<template>
    <div class="buttonModule">
        <template v-if="step>0">
            <button>
                <router-link 
                    @click="this.$store.dispatch('decreamentState')"
                    class="btn grey" 
                    :to="generatePathPrevious(step)"
                >RETOUR
                </router-link>
            </button>
        </template>
        <template v-if="step<3">
            <button :disabled="disableBtn()">
                <router-link 
                    @click="this.$store.dispatch('incrementState')"
                    class="btn red" 
                    :to="generatePathNext(step)"
                >
                <template v-if="this.step == 2">
                    LANCER LA SIMULATION
                </template>
                <template v-else>
                    CONTINUER
                </template>
                </router-link>
            </button>
        </template>
    </div>
</template>
  
  <script>
    import { mapState } from 'vuex';

  export default {
    name: 'ButtonsModuleComponent',
    computed:mapState([
      'step',
      'persons',
      'totalRevenue'
    ]),
    methods: {
        generatePathNext(step){
            switch (step) {
                case 0:
                    return "/revenu"
                case 1:
                    return "/accueil"
                case 2:
                    return "/resultat"
                default:
                    return "/"
            }
        },
        generatePathPrevious(step){
            switch (step) {
                case 1:
                    return "/"
                case 2:
                    return "/revenu"
                case 3:
                    return "/accueil"
                default:
                    return "/"
            }
        },
        disableBtn(){
            if(this.$route.fullPath == '/' && (this.persons.adults.length == 0 || this.persons.kids.length == 0)){return true}
            if(this.$route.fullPath == '/revenu' && this.totalRevenue == 0){return true}
            if(this.$route.fullPath == '/revenu' && this.totalRevenue == 0){return true}
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import "../styles/breakdown.scss";

  .buttonModule{
    display: flex;
    flex-flow:row wrap;
    justify-content: space-between;
    margin: 5rem 0 2rem 0;
    gap: 1rem;
    @include computer{
        gap: 3rem;
        justify-content: flex-end;
        flex-flow:row nowrap;
    }
    & button{
        width:100%;
        @include computer{
            width:initial;
        }
        & a{
            width:100%;
            min-width:205px;
        }
    }
  }
  </style>
  
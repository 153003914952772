import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUsers, faHandHoldingDollar, faSchool, faSquarePollHorizontal, faChevronRight, faMinus, faPlus, faXmark, faQuestion, faChevronUp, faChevronDown, faInfo } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUsers, faHandHoldingDollar, faSchool, faSquarePollHorizontal, faChevronRight, faMinus, faPlus, faXmark, faQuestion, faChevronUp, faChevronDown, faInfo)
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App)
.use(store)
.use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')

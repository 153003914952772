<template>
  <div class="stepper">
    <router-link to="/" :class="{ isActive : this.step>=0}">
      <font-awesome-icon 
        icon="users" 
        />
    </router-link>
    <font-awesome-icon 
      :class="{ isActive : this.step>=1 }" 
      class="chevron"  icon="chevron-right" 
      />
    <router-link to="/revenu" :disabled="this.step == 0" :class="{ isActive : this.step>=1 }" >
      <font-awesome-icon
        icon="hand-holding-dollar" 
        />
    </router-link>
    <font-awesome-icon 
      :class="{ isActive : this.step>=2 }" 
      class="chevron"  icon="chevron-right" 
      />
    <router-link to="/accueil" :disabled="this.step<2" :class="{ isActive : this.step>=2 }">
      <font-awesome-icon  
        icon="school" 
        />
    </router-link>
    <font-awesome-icon 
      :class="{ isActive : this.step>=3 }" 
      class="chevron"  icon="chevron-right" 
      />
    <router-link to="/resultat" :disabled="this.step<3" :class="{ isActive : this.step>=3 }">
      <font-awesome-icon  
        icon="square-poll-horizontal" 
        />
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StepperComponent',
  computed:mapState([
      'step',
  ]),
  created(){
    window.scroll(0,0);
  },
  methods: {
    setState(state) {
      if(state<this.step){
        this.$store.dispatch('setState',state)
      }
      return
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../styles/breakdown.scss";
  .stepper{
    width: 100%;
    margin: auto;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    padding: 1rem 0;
    @include computer(){
      width: 80%;
    }
    &>a{
      color: var(--red);
      opacity: 0.5;
      height: 55px;
      width: 55px;
      border:1px solid var(--red);
      border-radius: 100px;
      display:flex;
      justify-content:center;
      align-items:center;
      pointer-events:none;
      @include computer(){
        height: 65px;
        width: 65px;
      }
      &.isActive{
        background-color: var(--red);
        color: var(--white);
        opacity: 1;
        pointer-events:inherit;
      }
      & svg{
        height: 45%;
      }
    }
    & .chevron{
      color: var(--red);
      height: 21px;
      opacity: 0.5;
      @include computer(){
        height: 31px;
      }
      &.isActive{
        opacity: 1;
      }
    }
  }
</style>

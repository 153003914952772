<template>
  <div class="logo">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 765.3 667" >
      <polygon fill="#F3DAA5" points="393.9,0 473,105.4 441.1,128.8 473.4,168.2 222.6,354.3 143.6,249.3 175,226 142,182.9 "/>
      <polygon fill="#F3DAA5" points="505,158.4 584.1,263.8 552.2,287.1 583.4,328 332.1,511 254.7,407.7 286.1,384.4 253.1,341.2 "/>
      <polygon fill="#F6A90F" points="612,314 689.7,421.9 661.3,441.8 692,482.1 442.6,667 365.8,564.3 397.2,541 364.2,497.8 "/>
      <path d="M110.5,399.1L83.6,501.6H9.7l109-386.5h91.4L318,501.6h-76.1l-28.2-102.5H110.5z M203.5,343.5l-23.6-89
        c-6.1-23.9-12.2-54.4-17.4-78.2h-1.3c-5.1,24-10.9,55.2-16.8,78.2l-23.4,89L203.5,343.5z"/>
      <path d="M418.8,115.1h72.8v254.8c0,106.5-50.6,137.4-121.5,137.4c-17.9,0-38.9-3-52.1-7.8l7.9-59.8c11.9,3.7,24.4,5.6,36.9,5.5
        c34,0,56-15,56-75.8L418.8,115.1z"/>
      <path d="M750,331.3H624.3v109.3h141v61.1H551.5V115.1h206v61.1H624.3v95H750V331.3z"/>
    </svg>
  </div>
  <hgroup class="main-title">
    <h1>Calculateur des tarifs<br> du Réseau « Enfants Chablais »</h1>
  </hgroup>
  <nav>
    <div>
      <router-link :class="{red : this.$route.fullPath != '/explicatif', grey : this.$route.fullPath === '/explicatif' }" class="btn large" :to="generatePath()">CALCUL</router-link>
      <router-link :class="{red : this.$route.fullPath === '/explicatif', grey: this.$route.fullPath != '/explicatif'  }" class="btn large" to="/explicatif">EXPLICATIF</router-link>
    </div>
  </nav>
  <section class="content" >
    <router-view/>
  </section>
  <footer>
    <p>
      Réalisé par <a href="https://www.abclic.ch"> ABclic</a> 2023 & <a href="https://www.ananaweb.ch">Ananaweb</a>
    </p>
  </footer>
  <div class="goUp" :class="{isVisible: this.visible}" @click="scrollUp()">
    <font-awesome-icon icon="chevron-up" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name:"App",
  data(){
    return {
      visible:false
    }
  },
  mounted(){
    window.addEventListener('scroll',()=>{
      if(scrollY>500){
        this.visible = true;
      }else{
        this.visible = false;
      }
    })
  },
  computed:mapState([
      'step',
    ]),
  methods:{
    scrollUp(){
      window.scrollTo(0,0)
    },
    generatePath(){
      if(this.$route.fullPath == "/explicatif"){
        if(this.step == 0) return "/";
        if(this.step == 1) return "/revenu";
        if(this.step == 2) return "/accueil";
        if(this.step == 3) return "/resultat";
      }else{
        return "/";
      }
    }
  }
}

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Open+Sans:wght@300;400;500&display=swap');
@import "./styles/breakdown.scss";

:root{
  --border:1px solid #707070;
  --radiusSmall:3.75px;
  --radiusMedium:20px;
  --background:#F5F5F5;
  --black:#000;
  --dark: #707070;
  --white:#FFF;
  --red: #F6A90F;
  --redHover: #c88600;
  --blue: #868AD5;
  --orange: #D4573B;
  --grey:#D1D5DB;
  --creche:#9bbb59;
  --uape:#4bacc6;
  --afj:#c0504d;
  --shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/*---SCROLL BAR---*/
/*---
*/

::-webkit-scrollbar {
    width: 1rem;
}

::-webkit-scrollbar-track {
    background-color: var(--background);
    border-radius: 0.521vw;
}

::-webkit-scrollbar-thumb {
    border: 0.208vw solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 0.521vw;
    background-color: var(--red);
}


*{
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: inherit;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}

body{
  background-color: var(--background);
}

#app {
  width: 95%;
  margin: auto;
  @include computer(){
    width: 80%;
  }
}

nav{
  height: 100px;
  border-radius: var(--radiusMedium);
  margin-bottom: 1.5rem;
  @include computer(){
    margin-bottom: 3rem;
    width: 30%;
    margin: 1.5rem auto;
  }
  &>div{
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @include computer(){
      width: 80%;
    }
    a:nth-child(1){
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    a:nth-child(2){
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.logo{
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 50px;
  margin-top: 1rem;
  @include computer(){
    position: absolute;
    left: 2.5%;
    width: 6vw;
    transform: inherit;
    margin-top: 3rem;
  }
}

.content{
  position: relative;
  margin: auto;
  border-radius: var(--radiusMedium);
  box-shadow: var(--shadow);
  padding: 2rem 1rem;
  background-color: var(--white);
  @include computer(){
    padding: 2rem 4rem;
  }
  & form{
    display: flex;
    flex-flow: column wrap;
    margin: auto;
    gap: 1rem;
    @include computer(){
      flex-flow: row wrap;
    }
  }
}

hgroup{
  font-family: Lato, Arial, Helvetica, sans-serif;
  text-align: center;
  margin: auto;
  width: 100%;
  margin-top:3rem;     
  @include computer{
    margin-top:0;     
  }
  & h1{
    font-weight: 400;
    font-size: 25px;
    margin: 1.5rem 0;
    @include computer{
      margin: 3rem 0;
      font-size: 48px;
    }
  }
  & h2{
    font-size: 25px;
    font-weight: 400;
    @include computer(){
      font-size: 43px;
      margin-top: 2rem;
    }
  }
  & p{
    margin-top: 2rem;
    line-height: 1.8;
  }
}

h4{
  font-size: 1.2rem;
  margin-top: 1rem;
}

h5{
  font-size: 1rem;
}

h6{
  text-decoration: underline;
  font-weight: normal;
  font-size: 1rem;
}

.main-title{
  margin-top: -1rem;
  @include computer(){
    margin-top: 0
  }
}

.goUp{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 30px;
  height: 30px;
  transform:translateX(250%);
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: var(--radiusSmall);
  transition:transform 0.5s ease-in-;
  cursor: pointer;
  &:hover{
    color: var(--red);
    background-color: var(--white);
    border: 1px solid var(--red);
  }
  &.isVisible{
    transform:translateX(0);
    transition:transform 0.5s ease-in-out;
  }
}

button{
  border: none;
  background: none;
  appearance: none;
  cursor: pointer;
  font-size: 1rem;
  &:disabled{
    pointer-events: none;
    & a.btn{
      opacity: 0.5;
      background-color: var(--grey);
      border-color:var(--grey);
      color: #707070;
    }
  }
}

.btn{
  padding: 1rem 2rem;
  border-radius: var(--radiusMedium);
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  @include computer(){
    padding: 1rem 3rem;
  }
  &.large{
    width: 50%;
    @include computer(){
      padding: 1rem 8rem;
      min-width: 340px;
    }
  }
  &.red{
    border: 1px solid var(--red);
    background-color: var(--red);
    color: var(--white);
    &:hover{
      background-color: var(--redHover);
    }
  }
  &.white{
    border: var(--border);
    background-color: var(--white);
    color: var(--dark);
    &:hover{
      border: 1px solid var(--blue);
      background-color: var(--blue);
      color: var(--white);
    }
  }
  &.grey{
    background-color: var(--white);
    color: var(--red);
    border: 1px solid var(--red);
    &:hover{
      background-color: var(--background);
    }
  }
}

.input-block{
  width: 100%;
  @include computer{
    width: 48%;
  }
  & label{
    display: flex;
    align-items:center;
    font-size: 14px;
    width: 100%;
    @include computer{
      width: 70%;
      min-width: 365px;
      min-height: 60px;
    }
  }
  &.radio{
    display: flex;
    gap: 1rem;
    @include computer{
      height: 95px;
    }
    & label{
      width: auto;
      min-height: inherit;
      min-width: inherit;
      display:flex;
      gap: 0.5rem;
      @include computer{
        align-items:flex-end;

      }
    }
  }
  &>div{
    display:flex;
    align-items:center;
    height: 45px;
    gap: 1rem;
    margin:1rem 0;
    border-radius: var(--radiusSmall);
    @include computer{
      margin: 0;
    }
    &>div{
      position:relative;
      display:flex;
      justify-content:center;
      align-items:center;
      height: 45px;
      width: 45px;
      color: var(--red);
      &.selected{
        color: var(--white);
      }
      & span{
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color:currentColor;
        font-weight:600;
        z-index: 1;   
      }    
      & input[type="radio"].big {
        border-radius: var(--radiusSmall);
        height: 45px;
        width: 45px;
        &:before{
          width: 45px;
          height: 45px;
          border-radius: 0%;
          transform: scale(0);
          border-radius: var(--radiusSmall);
          transition: 120ms transform ease-in-out;
          background-color: var(--red); 
      }
      &:checked::before{
          transform: scale(1);
      }
      }
    }
  }
}

input[type=number]{
  width: 100%;
  min-width: none;
  height: 45px;
  margin: 1rem 0;
  border: 1px solid var(--black);
  border-radius: var(--radiusSmall);
  padding-left: 1rem;
  @include computer{
    width: 70%;
    min-width: 365px;
    margin: 0;
    margin-bottom: 1rem;
  }
  &:focus{
    border-color:var(--red);
  }
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}


input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--white);
    margin: 0;
    font: inherit;
    color: var(--red);
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    border: 1px solid var(--red);
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    &:before{
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: var(--red);    
    }
    &:checked::before{
        transform: scale(0.9);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


select{
  padding: 0.5rem;
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: var(--white);
  cursor:pointer;
}

.select{
  position: relative;
  margin-top: 0.5rem;
  padding: 1px;
  padding-right: 1rem;
  border: 1px solid var(--black);
  border-radius: var(--radiusSmall);
  &:focus-within{
    border-color: var(--red);
  }
}

.multiplySelect{
        position: relative;
        display: flex;
        flex-flow: row wrap;
        gap: 0.4rem;
        min-height: 38px;
        height: fit-content;
        max-height: 85px;
        padding: 0.5rem;
        padding-right: 2rem;
        margin-top: 0.5rem;
        border: 1px solid var(--black);
        background-color: var(--white);
        border-radius: var(--radiusSmall);
        overflow-y: scroll;
        overflow-x: hidden;
        &:focus-within{
          border-color:var(--red)
        }
        & label{
          position: sticky;
          top: 0;
          display: block;
          width: 110%;
          padding-top: 0.5rem;
          margin-bottom: 5px;
          font-size: 12px;
          background: var(--white);
          z-index: 2;
        }
        & select{
          position: absolute;
          top: 0;
          left: 0;
          margin-top: 0;
          opacity: 0;
          width: 100%;
          height:100%;
        }
        & .selectChip{
          display: flex;
          align-items: center;
          background-color: rgba(75,172,198,0.6);
          color: var(--white);
          margin: 5px 0;
          font-size: 12px;
          border-radius: 30px;
          padding: 5px;
          width: max-content;
          gap: 5px;
          & span{
            display:flex;
            justify-content:center;
            align-items:center;
            background-color: #4bacc6;
            border-radius: 50px;
            color: var(--white);
            width: 20px;
            height: 20px;
            cursor: pointer;
            z-index: 1;
            & svg{
              pointer-events: none;
            }
          }
          
        }
        & .placeholder{
          font-size: 14px;
          color: var(--dark);
        }
}

.chevron-down{
      position: absolute;
      right: 0px;
      top: 2px;
      color: var(--red);
      padding: 0.5rem;
      border-radius: 50px;
    }

a{
  display:block;
  color: inherit;
}

i{
  display: contents;
}

.deleteCard{
    position:absolute;
    top: 0;
    right: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 35px;
    width: 35px;
    background-color: var(--red);
    color: var(--white);
    border-bottom-left-radius: var(--radiusSmall);
    cursor: pointer;
    &:hover{
      background-color:var(--redHover) ;
    }
}

footer:not(article footer){
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 1rem 0;
  margin-top: 3rem;
  border-top: var(--border);
  & p{
    font-size: 12px;
  }
  & a{
    display:inline;
  }
}

.tableBlock{
    width: 100%;
    margin: auto;
    border-radius: var(--radiusMedium);
    box-shadow: var(--shadow);
    overflow: hidden;
    margin-top: 3rem;
    @include computer{
        width: 80%;       
    }
    & .table{
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;
        @include computer{
            text-align: left;
            font-size: 16px;
        }
        &.creche th{
          background-color: var(--creche);
        }
        &.uape th{
          background-color: var(--uape);
        }
        &.afj th{
          background-color: var(--afj);
        }
        & th{
            background-color: var(--red);
            color: var(--white);
            font-weight: 500;
            & span{
              font-size: 12px;
              font-style: italic;
            }
        }
        & th,td{
            padding: 1rem 5px;
            @include computer{
                padding: 1rem;
            }
        }
    }
}

.tableLeftHeaded{
  width: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 102%;
  padding-bottom: 1rem;
  border-radius: var(--radiusMedium);
  box-shadow: var(--shadow);
  overflow: hidden;
  margin-top: 3rem;
  & .head{
    color: var(--red);
    font-weight: 500;
    @include computer{
      padding-left: 1rem;
    }
    & .column-cell{
      margin-left: 1rem;
    }
    & span{
      font-size: 12px;
      font-style: italic;
      color: var(--black);
    }
  }
  &.afj>.head{
    color: var(--afj);
  }
  & .column{
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    padding-top: 1rem;
    @include computer{
      grid-template-rows: 15% repeat(4, auto);
    }
    &.right{
      & .column-cell{
        margin-right: 1rem;
      }
    }
  }
  & .column-cell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2vw;
    & p{
      padding: 1rem 0;
      &.afj{
        color: var(--afj);
        font-weight: 500;
      }
      &.uape{
        color: var(--uape);
        font-weight: 500;
      }
      &.creche{
        color: var(--creche);
        font-weight: 500;
      }
    }
  }
  & .column-cell:not(.last){
    border-bottom: 1px solid var(--grey);
  }
  .column-cell:not(.head .column-cell) {
    justify-content: center;
  }
  .column-cell:not(.head .column-cell.double) {
    justify-content: flex-end;
  }
}

.informationBox{
  display: inline-block;
  position: static;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: var(--red);
  border: 1px solid var(--red);
  color: var(--white);
  border-radius: 50px;
  transform: translateY(2px);
  cursor: pointer;
  @include computer{
    position: relative;
  }
  &>svg{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-95%);
  }
}
.info{
  border-radius: var(--radiusMedium);
  position: fixed;
  top: 50%;
  left: 50%;
  transform:translateX(-50%) translateY(-50%);
  box-shadow: var(--shadow);
  max-height: -moz-min-content;
  width:100%;
  max-height: min-content;
  overflow: hidden;
  z-index:2;
  @include computer{
    min-width: 800px;
    width: -moz-fit-content;
    width: fit-content;
  }
  & .headerInfo{
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--red);
    padding: 1rem 2rem;
    color:var(--white);
    & .closeInfo{
    height: 20px;
    color:var(--white);
    cursor:pointer;
    }
  }
  & .contentInfo{
    padding: 1rem 1rem 2rem 2rem;
    background-color: var(--white);
    color: var(--black);
  }
}

</style>

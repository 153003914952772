<template>
    <Stepper/>
    <hgroup>
      <h2>Situation familiale</h2>
      <p>
        <strong>Tarifs valables dès janvier 2024</strong><br>
        Les montants sont donnés à titre indicatif, seul le contrat d’accueil établi par le Réseau « Enfants Chablais » fait foi pour le calcul du coût. 
      </p>
    </hgroup>
    <form class="genericData">
      <div>
          <p>
            <strong>Nombre d'adultes</strong> faisant ménage commun avec l'enfant
            <span @click="this.showInfo = !this.showInfo" class="informationBox">
              <font-awesome-icon size="xs" icon="info" />
            </span>
          </p>
        <div>
          <button type="button" :disabled="this.persons.adults.length <= 1" @click="this.$store.dispatch('shiftAdult')" class="incrementer">
            <font-awesome-icon icon="minus" />
          </button>
          <span>{{ persons.adults.length }}</span>
          <button type="button" @click="this.$store.dispatch('addAdult')" class="incrementer">
            <font-awesome-icon icon="plus" />
          </button>
        </div>
      </div>
      <div>
        <p>
          <strong>Nombre d’enfants</strong> accueillis dans le Réseau « Enfants Chablais » 
          <span @click="this.showInfoKid = !this.showInfoKid" class="informationBox">
              <font-awesome-icon size="xs" icon="info" />
          </span>
        </p>
        <div>
          <button type="button" :disabled="this.persons.kids.length <= 1" @click="this.$store.dispatch('shiftKid')" class="incrementer">
            <font-awesome-icon icon="minus" />
          </button>
          <span>{{ persons.kids.length }}</span>
          <button type="button" @click="this.$store.dispatch('addKid')" class="incrementer">
            <font-awesome-icon icon="plus" />
          </button>
        </div>
      </div>
    </form>
    <ButtonsModule/>
    <div class="info" v-if="showInfo">
        <div class="headerInfo">
          <h4>Aide</h4>
          <font-awesome-icon @click="this.showInfo = false" class="closeInfo" icon="xmark"/>
        </div>
        <div class="contentInfo">
          <p>Les adultes vivant en ménage commun font partie de l’Unité Economique de Référence (UER) pour le calcul du Revenu Déterminant (RD).<br>
          <br>
          <strong>Font partie de l’UER :</strong> les adultes, soit les pères et mères, beaux-pères et belles-mères de l’enfant, partenaires enregistrés et concubins en union libre. Les adultes vivant de fait en commun, mariés ou non mariés, même si l’un des deux n’est pas le parent biologique de l’enfant, et même si le/la partenaire ne reconnaît pas l’enfant, font partie de l’UER. 
          <br>
          <br>
          <strong>Ne font pas partie de l’UER :</strong> les enfants mineurs ou majeurs, à charge ou non des parents, les colocataires, les sous-locataires, les autres adultes de la famille (p.ex. grands-parents), les jeunes au pair. Une exception est admise si l’un de ces membres détient la tutelle officielle de l’enfant. 
          Les parents autorisent le réseau à se renseigner auprès des autorités compétentes (administration communale, office de la population) disposant des informations liées à la domiciliation et à la composition du ménage. 
          En cas de garde partagée pour des parents vivant séparés, il sera considéré deux ménages distincts avec deux contrats d’accueil.
          </p>
        </div>
    </div>
    <div class="info" v-if="showInfoKid">
            <div class="headerInfo">
            <h4>Rabais fratrie</h4>
            <font-awesome-icon @click="this.showInfoKid = false" class="closeInfo" icon="xmark"/>
            </div>
            <div class="contentInfo">
              <p>Familles ayant 2 enfants accueillis dans le réseau : rabais de <strong>20%</strong></p>
              <p>Familles ayant 3 enfants accueillis dans le réseau : rabais de <strong>30%</strong></p>
              <p>Familles ayant 4 enfants ou plus accueillis dans le réseau : rabais de <strong>40%</strong></p>
            </div>
        </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Stepper from '@/components/Stepper.vue'
import ButtonsModule from '@/components/ButtonsModule.vue';

export default {
  name: 'CalculateurView',
  data(){
    return {
      showInfo:false,
      showInfoKid:false,
    }
  },
  created() { 
    this.$store.dispatch('setState',0)
  },
  mounted() {
    window.scrollTo(0,0);
  },
  computed:mapState([
      'persons',
  ]),
  components: {
    Stepper,
    ButtonsModule
  }
}
</script>

<style scoped lang="scss">
@import "../styles/breakdown.scss";

.genericData{
  margin-top: 2rem;
  &>div{
    width: 100%;
    margin: auto;
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-top: 3rem;
    @include computer{
      width: 60%;
    }
    &>div{
      display: flex;
      gap: 8px;
      align-items: center;
      @include computer{
        gap: 1rem;
      }
    }
  }
}

.incrementer{
  border: 1px solid var(--red);
  color:var(--red);
  border-radius: var(--radiusSmall);
  background-color: var(--white);
  height: 22px;
  width: 22px;
  display:flex;
  justify-content:center;
  align-items:center;
  @include computer{
    height: 36px;
    width: 36px;
    &:hover{
      background-color: var(--red);
      & svg{
        color: var(--white);
      }
    }
  }
  & svg{
    color: currentColor
  }
  &:disabled{
    color:var(--grey);
    border-color:var(--grey);
    pointer-events:none;
  }
}

</style>
import { createStore } from 'vuex'
import { v4 as uuidv4 } from "uuid";

export default createStore({
  state: {
    step:0,
    persons:{
      adults:[{
        id:uuidv4(),
        revenue:0,
        salaireMensuel:0,
        quantiteSalaire:"13",
        revenueIndependant:0,
        PCFamilles:0,
        allocationsFamilliales:0,
        renteAVS:0,
        pensionAlimentairesRecues:0,
        indemnitePerteDeGain:0,
        pensionsAlimentairesPayees:0,
        indemniteChomage:0,
        autreRevenue:0
      }],
      kids:[{
        id:uuidv4(),
        type:"creche",
        service:"mensuel",
        tarifHoraire:0,
        cout:0,
        occupation:0,
        monday:0,
        tuesday:0,
        wednesday:0,
        thursday:0,
        friday:0,
        uape:{
          monday:[],
          tuesday:[],
          wednesday:[],
          thursday:[],
          friday:[],
        }
      }]
    },
    totalRevenue:0,
  },
  getters: {
  },
  mutations: {
    incrementState(state){
      state.step<=2&& state.step++
    },
    decreamentState(state){
      state.step>=1&& state.step--
    },
    setState(state,num){
      state.step = num
    },
    addAdult(state){
        let adult = {
          id: uuidv4(),
          revenue:0,
          salaireMensuel:0,
          quantiteSalaire:"13",
          revenueIndependant:0,
          PCFamilles:0,
          allocationsFamilliales:0,
          renteAVS:0,
          pensionAlimentairesRecues:0,
          indemnitePerteDeGain:0,
          pensionsAlimentairesPayees:0,
          indemniteChomage:0,
          autreRevenue:0
        }
        state.persons.adults.length<=5&& state.persons.adults.push(adult);
    },
    removeAdult(state,adult){
      if(state.persons.adults.length>=2) {
        let updatedAdults = state.persons.adults.filter(e=> e.id != adult.id);
        state.persons.adults = updatedAdults;
        state.totalRevenue = state.totalRevenue-adult.revenue > 165000 ? 165000 : state.totalRevenue-adult.revenue;
      }
    },
    shiftAdult(state){
      state.persons.adults.length>=2&& state.persons.adults.shift();
    },
    addKid(state){
      const newKid = {
        id:uuidv4(),
        type:"creche",
        service:"mensuel",
        tarifHoraire:0,
        cout:0,
        occupation:0,
        monday:0,
        tuesday:0,
        wednesday:0,
        thursday:0,
        friday:0,
        uape:{
          monday:[],
          tuesday:[],
          wednesday:[],
          thursday:[],
          friday:[],
        }
      };
      state.persons.kids.length<=11&& state.persons.kids.push(newKid);
    },
    shiftKid(state){
      state.persons.kids.length>=2&& state.persons.kids.shift();
    },
    removeKid(state,id){
      if(state.persons.kids.length>=2) {
        let updatedKids = state.persons.kids.filter(e=> e.id != id);
        state.persons.kids = updatedKids;
      } 
    },
    updateKid(state,kid){
      const index = state.persons.kids.findIndex(k=>k.id == kid.id);
      state.persons.kids[index] = kid;
    },
    updateAdult(state,currentAdult){
      let index = state.persons.adults.findIndex(adult => adult.id == currentAdult.id);
      state.persons.adults[index] = currentAdult;

      let total = 0;
      state.persons.adults.forEach(adult=> total+=adult.revenue);
      if(total<25000){
        state.totalRevenue = 25000;
      }else if(total>165000){
        state.totalRevenue = 165000;
      }else{
        state.totalRevenue = total;
      }
    },
    setService(state,kid){
      let index = state.persons.kids.findIndex(k => k.id == kid.id);
      state.persons.kids[index] = kid;
    },
    updateUAPEOccupation(state, kid){
      let index = state.persons.kids.findIndex(k => k.id == kid.id);
      state.persons.kids[index] = kid;
    }
  },
  actions: {
    incrementState({commit}){
      commit("incrementState");
    },
    decreamentState({commit}){
      commit("decreamentState");
    },
    setState({commit}, num){
      commit("setState", num);
    },
    addAdult({commit}){
      commit("addAdult");
    },
    removeAdult({commit},adult){
      commit("removeAdult",adult);
    },
    shiftAdult({commit}){
      commit("shiftAdult");
    },
    addKid({commit}){
      commit("addKid");
    },
    shiftKid({commit}){
      commit("shiftKid");
    },
    removeKid({commit},id){
      commit("removeKid",id);
    },
    updateKid({commit},kid){
      commit('updateKid',kid);
    },
    updateAdult({commit},adult){
      commit("updateAdult",adult);
    },
    setService({commit},kid){
      commit("setService",kid);
    },
    updateUAPEOccupation({commit},kid){
      commit("updateUAPEOccupation",kid);
    },
  },
  modules: {
  }
})

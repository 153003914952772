import { createRouter, createWebHashHistory } from 'vue-router'
import CalculateurView from "../views/CalculateurView.vue";

const routes = [
  {
    path: '/',
    name: 'calculateur',
    component: CalculateurView
  },
  {
    path: '/revenu',
    name: 'Revenu',
    component: () => import(/* webpackChunkName: "Revenue" */ '../views/RevenueView.vue')
  },
  {
    path: '/accueil',
    name: 'Accueil',
    component: () => import(/* webpackChunkName: "Accueil" */ '../views/AccueilView.vue')
  },
  {
    path: '/resultat',
    name: 'Resultat',
    component: () => import(/* webpackChunkName: "Resultat" */ '../views/ResultatView.vue')
  },
  {
    path: '/explicatif',
    name: 'explicatif',
    component: () => import(/* webpackChunkName: "Explicatif" */ '../views/Explicatif.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
